import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box, Container, TextField, IconButton } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { ORG_FETCH_PARTICULAR , ORG_UPDATERATING} from '../../api/org';
import dayjs from 'dayjs';
const OrgBookings = () => {
  const { id } = useParams(); // Get orgId from URL params
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRating, setEditingRating] = useState({});

  // Fetch bookings for the orgId
  useEffect(() => {
    axios.get(ORG_FETCH_PARTICULAR+id)
      .then((response) => {
        setBookings(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching bookings:', error);
        setLoading(false);
      });
  }, [id]);

  // Handle rating change
  const handleRatingChange = (bookingId, value) => {
    setEditingRating((prev) => ({
      ...prev,
      [bookingId]: value,
    }));
  };

  // Save edited rating
  const saveRating = (bookingId) => {
    const rating = editingRating[bookingId];
    axios.put(`${ORG_UPDATERATING}${bookingId}/rating`, { rating })
      .then((response) => {
        // Update the local bookings state with the new rating
        setBookings((prev) =>
          prev.map((booking) =>
            booking._id === bookingId ? { ...booking, rating: response.data.rating } : booking
          )
        );
        // Clear the editing rating state
        setEditingRating((prev) => {
          const newState = { ...prev };
          delete newState[bookingId];
          return newState;
        });
      })
      .catch((error) => {
        console.error('Error saving rating:', error);
      });
  };

  // Define columns for DataGrid
  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'whatsappNumber', headerName: 'WhatsApp', width: 150 },
    { field: 'studentCode', headerName: 'Student Code', width: 150 },
    { field: 'bookingDate', headerName: 'Booking Date', width: 150,  valueGetter: (params) => params.row.bookingDate ? dayjs(params.row.bookingDate).isValid()  && dayjs(params.row.bookingDate).format('DD MMM YYYY') : 'Not Booked', },
    { field: 'time', headerName: 'Booking Time', width: 150 },
    { field: 'rating', headerName: 'Rating', width: 150, renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={editingRating[params.row._id] || params.row.rating}
            onChange={(e) => handleRatingChange(params.row._id, e.target.value)}
            size="small"
            type="number"
            inputProps={{ min: 0, max: 5 }}
            sx={{ width: 60, marginRight: 1 }}
          />
          <IconButton
            onClick={() => saveRating(params.row._id)}
            color="primary"
            size="small"
          >
            <SaveIcon />
          </IconButton>
        </Box>
      )
    },
  ];

  return (
    <Container sx={{width: '100%'}}>
      <Box sx={{ mt: 4 , width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Organization Bookings
        </Typography>
        <Box sx={{ height: '90vh', width: '100%' }}>
          <DataGrid
            rows={bookings}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row._id} // Use _id as the unique identifier for each row
            loading={loading}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default OrgBookings;
