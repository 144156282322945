const {BASE_URL} = require('./client');

const ORG_FETCH = BASE_URL + '/api/org/fetch';
const ORG_FETCH_PARTICULAR = BASE_URL + '/api/org/fetch/';
const ORG_UPDATERATING = BASE_URL + '/api/org/bookings/';


module.exports = {
    ORG_FETCH,
    ORG_FETCH_PARTICULAR,
    ORG_UPDATERATING
}